import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from './store';
import vClickOutside from 'click-outside-vue3'

const app = createApp(
  App
);

import Vue3Tour from 'vue3-tour';

require('vue3-tour/dist/vue3-tour.css');

app.use(Vue3Tour);


import Vidle from 'v-idle-3';

app.use(Vidle);

/**
 * Fontawesome Icons
 */
import { library } from '@fortawesome/fontawesome-svg-core';

// Solid
import {
  faBars,
  faCoffee as fasCoffee,
  faUser, // Person
  faUsers, // Subscribers
  faBan,
  faPen,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheckCircle, // Checklist for intake screens
  faTimesCircle,
  faCircle as fasCircle,
  faInfoCircle, // Help & Faq
  faRedoAlt, // Refresh
  faMapMarkerAlt,
  faQuestionCircle,
  faPlay,
  faPause,
  faLink,
  faGlobe,
  faUserSlash,
} from '@fortawesome/pro-solid-svg-icons';

// Regular
import {
  faCoffee as farCoffee,
  faSearchPlus,
  faTrash,
  faFilter, // Filter  or you can use this fas fa-sort-amount-down
  faImage, // Live Feed
  faImages, // Image View Link
  faCalendarDay, // Maintenance code, Status changed
  faBuilding, // West Main Building:
  faStickyNote, // Notes
  faSitemap,
  faAddressCard,
  faEnvelope,
  faPhone,
  faMobile,
  faExclamationCircle,
  faExclamationTriangle,
  faEdit,
  faDownload,
  faShareSquare,
  faEye,
  faTrashAlt,
  faCheck,
  faTimes,
  faPlus,
  faMinus,
  faToggleOn,
  faToggleOff,
  faFileCsv,
  faFileArchive,
} from '@fortawesome/pro-regular-svg-icons';

// Light
import {
  faCoffee as falCoffee,
  faEllipsisV, // Sub navigation
  faFileAlt, // View Log
  faMapMarkedAlt, // Map Marker google map, Room 311
  faMap, // University of Utah Map Location
  faCircle, // Checklist for intake screens
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faPlusCircle, // Add new device, connection, etc
  faPaste,
  faExternalLinkAlt,
} from '@fortawesome/pro-light-svg-icons';

// Duotone
import { faCoffee as fadCoffee } from '@fortawesome/pro-duotone-svg-icons';

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

library.add(
  // Solid
  faBars,
  fasCoffee,
  faUser,
  faUsers,
  faBan,
  faPen,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  fasCircle,
  faInfoCircle,
  faRedoAlt,
  faMapMarkerAlt,
  faQuestionCircle,
  faPlay,
  faPause,
  faLink,
  faGlobe,
  faTrash,
  faUserSlash,
  // Regular
  farCoffee,
  faSearchPlus,
  faFilter,
  faImage,
  faImages,
  faCalendarDay,
  faBuilding,
  faStickyNote,
  faSitemap,
  faAddressCard,
  faEnvelope,
  faPhone,
  faMobile,
  faExclamationCircle,
  faExclamationTriangle,
  faEdit,
  faDownload,
  faShareSquare,
  faEye,
  faTrashAlt,
  faCheck,
  faTimes,
  faPlus,
  faMinus,
  faToggleOn,
  faToggleOff,
  faFileCsv,
  faFileArchive,
  // Light
  falCoffee,
  faEllipsisV,
  faFileAlt,
  faMapMarkedAlt,
  faMap,
  faCircle,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faPaste,
  faPlusCircle,
  faExternalLinkAlt,
  // Duotone
  fadCoffee,
);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('font-awesome-layers', FontAwesomeLayers);
app.component('font-awesome-layers-text', FontAwesomeLayersText);

/** End Fontawesome Icons */


import 'leaflet/dist/leaflet.css';

app.use(vClickOutside);

import sanitizeHTML from 'sanitize-html';

app.config.globalProperties.$sanitize = sanitizeHTML

app.use(store);

app.use(router);

app.mount('#app');
