import store from '@/store'

export const baseUri = process.env.VUE_APP_BASE_URI;

let controller = new AbortController();
let signal = controller.signal;

export async function handleRequest(method, url, data = null, additionalHeaders = null) {

    let operation = method === "GET" ? "read" : "write";

    let currentUser = await store.dispatch('updateCurrentUser', operation);

    if ( url.search("undefined") > -1) {
        throw {status: "handleRequest failure",
               statusText: "URL contains undefined and is invalid",
               url: url,
        };
    }

    if (currentUser === null) {
        throw {status: "handleRequest failure",
               statusText: "Couldn't get currentUser",
        };
    }

    let token = currentUser.accessToken ? currentUser.accessToken :
                currentUser.idToken     ? currentUser.idToken : "";
    
    const headers = new Headers({
        Accept: 'application/json',
    });

    if (additionalHeaders) {
        Object.entries(additionalHeaders).forEach((entry) => {
            let [key, value] = entry;
            headers.set(key, value);
        });
    }

    headers.set("X-SS-Authorization", token);

    const subscriptionKeyName = "Ocp-Apim-Subscription-Key";
    headers.set(subscriptionKeyName, process.env.VUE_APP_SUBSCRIPTION_KEY);
    let endpoint = `${baseUri}${url}`;

    if (url.search("http") > -1) {
        endpoint = `${url}`;
    }

    let options = {
        method: method,
        headers: headers,
        keepalive: true
    };

    if (data) {
        if (data instanceof File) {
          options.body = await data.text();
        } else {
          options.body = JSON.stringify(data);
        }
    }

    const response = await fetch(endpoint, options, {signal});

    if (!response.ok) {
        throw response;
    }

    // A successful DELETE request
    // will return a 204 status
    if (response.status === 204) {
        return {};
    }

    return response;

}

export async function handleRequestJson(method, url, data = null, additionalHeaders = null) {
    const response = await handleRequest(method, url, data, additionalHeaders);
    return response.json ? await response.json() : {};
}

export var api = {

    getResponse: (url) => {
        return handleRequest('GET', url);
    },
    /**
     * GET request
     */
    get: (url) => {
        return handleRequestJson('GET', url);
    },

    /**
     * POST request
     */
    post: (url, data, additionalHeaders = null) => {
        return handleRequestJson('POST', url, data, additionalHeaders);
    },

    /**
     * POST request
     */
    postFile: (url, data, additionalHeaders = null) => {
        return handleRequest('POST', url, data, additionalHeaders);
    },

    /**
     * PUT request
     */
    put: (url, data) => {
        return handleRequestJson('PUT', url, data);
    },

    /**
     * PATCH request
     */
    patch: (url, data) => {
        return handleRequestJson('PATCH', url, data);
    },

    /**
     * DELETE request
     */
    delete: (url) => {
        return handleRequestJson('DELETE', url);
    },

    abort: () => {
        controller.abort();
    }

};