import { api } from "@/modules/apiv2";

export default {
  state: {
    user: {},
    users: [],
    subgroups: {},
  },

  getters: {
    user(state) {
      return state.user;
    },

    users(state) {
      return state.users;
    },

    subgroups(state) {
      return state.subgroups;
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },

    setUsers(state, users) {
      state.users.splice(0, state.users.length, ...users);
      state.users = users;
    },
    setSubgroups(state, subgroups) {
      state.subgroups = subgroups;
    },
    setUserSubgroups(state, subgroups) {
      state.user.ContactInfo.Organization = subgroups;
    }
  },

  actions: {
    getUser({ commit }, id) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/appsubscribers/${id}`
        )
        .then((data) => {
          commit('setUser', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getUsers({ commit }) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/appsubscribers`
        )
        .then((data) => {
          commit('setUsers', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getPatrols({ commit }) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/appsubscribers`
        )
        .then((data) => {
          data = data.filter(user => user.IsProvisioned === true && user.Type === "Patrol" && user.Status === "Active");
          commit('setUsers', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getSubgroups({ commit }) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/appsubscribers`
        )
        .then((subscribers) => {
          let subgroups = [];

          for (const subscriber of subscribers) {
            if (subscriber["Type"] === "Patrol" && subscriber["IsProvisioned"] === true) {
              const groups = subscriber["ContactInfo"]["Organization"].split(',');
              for (const group of groups) {
                const trimmedGroup = group.trim();
                let foundValue = false;
                for (const subgroup of subgroups) {
                  if (subgroup["GroupName"] == trimmedGroup) {
                    subgroup["Users"].push(subscriber);
                    foundValue = true;
                  }
                }
                if (!foundValue) {
                  subgroups.push({
                    GroupName: trimmedGroup,
                    Users: [subscriber],
                    Excluded: [],
                  })
                }
              }
            }
          }
          commit('setUsers', subscribers);
          commit('setSubgroups', subgroups);
          return subgroups;
        })
        .catch((error) => {
          throw error;
        })
    },

    searchUsers({ commit }, searchTerm) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/appsubscribers/search?noDispatch=1&searchTerms=${searchTerm}`
        )
        .then((data) => {
          commit('setUsers', data);
          return data;
        })
        .catch((error) => {
          console.error("No search results: ", error);
          commit('setUsers', []);
          throw error;
        });
    },

    searchPatrols({ commit }, searchTerm) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/appsubscribers/search?searchTerms=${searchTerm}`
        )
        .then((data) => {
          commit('setUsers', data);
          return data;
        })
        .catch((error) => {
          console.error("No search results: ", error);
          commit('setUsers', []);
          throw error;
        });
    },

    importUsers({ commit }, csvFile) {
      const APP_BASE_URI = process.env.VUE_APP_BASE_URI;

      const baseUrl = this.getters.baseUrlOrg;

      let additionalHeaders = {
        'Content-Length': csvFile.size,
        'Content-Type': csvFile.type,
      };

      return api.post(
        `${APP_BASE_URI}${baseUrl}/appsubscribers/bulkimport`,
        csvFile,
        additionalHeaders
      )
      .then((response) => {
        commit('setUser', null);
        return response;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    },

    createUser({ commit }, data) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .post(
          `${baseUrl}/appsubscribers`,
          data
        )
        .then((response) => {
          commit('setUser', response.account);
          return response.account;
        })
        .catch((error) => {
          throw error;
        });
    },

    updateUser({ commit }, data) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .put(
          `${baseUrl}/appsubscribers/${data.id}`,
          data
        )
        .then((data) => {
          commit('setUser', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    removeUserFromSubgroup({ commit, dispatch }, data) {
      commit('setUser', data.user);
      let currentSubgroups = data.user.ContactInfo.Organization.split(',');
      if (currentSubgroups.length == 1) {
        return 'User must have at least one subgroup';
      }
      currentSubgroups = currentSubgroups.filter(group => group !== data.name);
      commit('setUserSubgroups', currentSubgroups.join(","));
      dispatch('updateUser', data.user);
      return 'User Removed from Group'
    },

    addUserToSubgroup({ commit, dispatch}, data) {
      commit('setUser', data.user);
      let currentSubgroups = data.user.ContactInfo.Organization.split(',');
      // Make sure user is not added to group twice
      currentSubgroups = currentSubgroups.filter(group => group !== data.name);
      currentSubgroups.push(data.name)
      commit('setUserSubgroups', currentSubgroups.join(","));
      dispatch('updateUser', data.user);
      return 'User Added to Group'
    },

    deleteUser({ commit }, id) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .delete(
          `${baseUrl}/appsubscribers/${id}`
        )
        .then(() => {
          commit('setUser', null);
          return;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
