import { api } from "@/modules/apiv2";

export default {
    state: {
        webChatURL: ""
    },

    getters: {
        webChatURL(state) {
            return state.webChatURL;
        }
    },

    mutations: {
        setWebChatUrl(state, webChatURL) {
            state.webChatURL = webChatURL;
        }
    },

    actions: {
        async populateWebChatURL({ commit, getters }) {

            const currentUser = getters['currentUser'];
            const baseUrl = getters['baseUrl'];
            let data; 
            try {
                data = await api.get(`${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/webbot`)
            } catch (e) {
                console.error("Retrieved FAQ bot Failure")
                commit('setWebChatUrl', 'error');
                throw e;
            }

            commit('setWebChatUrl', data);
            return data;
        }
    }
}
