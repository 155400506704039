<template>
  <div>
    <nav class="main-navigation bg-vpi-blue">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-14">
          <div class="logo flex items-center uppercase text-gray-600 text-base">
            <router-link
              to="/"
              class="logo flex items-center uppercase text-gray-600 text-base welcomelink"
            >
              <img
                class="w-8 h-8"
                src="@/assets/svg/sentry-secure-logo.svg"
                alt="Sentry Secure Logo"
              />
              <span class="lg:inline text-white text-lg mx-4">Sentry-SECURE</span>
            </router-link>
            <span class="hidden lg:inline text-white text-lg ml-4">{{ portalType }}</span>
            <span class="hidden lg:inline text-white text-lg ml-4">
              {{ currentUserName }} {{ currentUserOrgConfig }}
            </span>
          </div>
          <div v-if="currentUserIsDispatch">
            <div class="hidden md:block">
              <div class="ml-10 flex">
                <router-link
                  v-if="currentOrgType === 'consumer'"
                  to="/event-dispatcher"
                  class="mt-1 block cadLink"
                >
                  Dispatcher
                </router-link>
                <button
                  class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white"
                  type="button"
                  v-on:click="$emit('start-tour')"
                >
                  Help
                </button>
                <button
                  class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white logoutLink"
                  type="button"
                  @click.prevent="logout()"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
          <div v-else-if="currentUserIsImageViewer">
            <div class="hidden md:block">
              <div class="ml-10 flex">
                <button
                  class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white"
                  type="button"
                  v-on:click="$emit('start-tour')"
                >
                  Help
                </button>
                <button
                  class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white logoutLink"
                  type="button"
                  @click.prevent="logout()"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="hidden md:block">
              <div class="ml-10 flex">
                <button
                  class="p-2 text-sm text-gray-450 hover:text-white focus:outline-none focus:text-white helpLink"
                  type="button"
                  v-on:click="$emit('start-tour')"
                >
                  Help
                </button>
                <router-link
                  v-if="currentOrgType === 'owner'"
                  to="/devices"
                  class="devicesLink ml-4"
                >
                  Devices
                </router-link>
                <router-link
                  v-if="currentOrgType === 'consumer'"
                  to="/users"
                  class="appUsersLink ml-4"
                >
                  Users
                </router-link>
                <router-link to="/connections" class="connectionsLink ml-4">
                  Connections
                </router-link>
                <router-link to="/events" class="eventsLink ml-4">Events</router-link>
                <router-link to="/accounts" class="accountsLink ml-4">Accounts</router-link>

                <button
                  class="px-3 text-vpi-bluegray hover:text-white kabobMenu"
                  aria-label="Menu"
                  aria-haspopup="true"
                  @click="toggleDropdownMenu"
                >
                  <font-awesome-icon :icon="['fal', 'ellipsis-v']" size="2x" />
                </button>

                <div
                  v-if="dropdownMenu.open"
                  class="origin-top-right absolute right-0 w-48 shadow-xl z-20"
                  style="top: 64px"
                  v-click-outside="closeDropdownMenu"
                >
                  <div
                    class="px-3 pt-2 pb-4 bg-vpi-blue shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <router-link to="/downloads" class="mt-1 block downloadsLink">
                      Downloads
                    </router-link>
                    <router-link to="/instructions" class="mt-1 block instructionsLink">
                      Instructions
                    </router-link>
                    <router-link to="/training" class="mt-1 block trainingLink">
                      Training
                    </router-link>
                    <router-link to="/nonRMSEvents" class="mt-1 block nonRMSEventsLink">
                      Banners and Emails
                    </router-link>
                    <router-link
                      :to="`/configuration/${currentOrgId}`"
                      class="mt-1 block configurationLink"
                    >
                      Configuration
                    </router-link>
                    <router-link
                      v-if="currentOrgType === 'consumer'"
                      to="/users/subgroups"
                      class="mt-1 block subgroupsLink"
                    >
                      Sub Groups
                    </router-link>
                    <router-link
                      v-if="currentOrgType === 'consumer'"
                      to="/event-dispatcher"
                      class="mt-1 block dispatcherLink"
                    >
                      Dispatcher
                    </router-link>
                    <router-link
                      v-if="currentOrgType === 'consumer'"
                      to="/backend-subscriber-configuration"
                      class="mt-1 block cadLink"
                    >
                      CAD Config
                    </router-link>
                    <router-link
                      v-if="currentOrgType === 'consumer'"
                      to="/proxy"
                      class="mt-1 block proxyLink"
                    >
                      Proxy Config
                    </router-link>
                    <router-link
                      v-if="currentOrgType === 'owner'"
                      to="/summary"
                      class="mt-1 block summaryLink"
                    >
                      Summary
                    </router-link>

                    <hr class="border-gray-700 my-2" />

                    <router-link
                      :to="`/accounts/${currentAccountId}`"
                      class="mt-1 block viewYourAccountLink"
                    >
                      Your Account
                    </router-link>
                    <button
                      class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white changePasswordLink"
                      type="button"
                      @click.prevent="changeAccountPassword()"
                    >
                      Change Password
                    </button>
                    <router-link :to="supportPath" class="mt-1 block supportLink">
                      Support
                    </router-link>
                    <button
                      class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white logoutLink"
                      type="button"
                      @click.prevent="logout()"
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              </div>

              <div class="ml-4 flex items-center md:ml-6">
                <!-- Profile dropdown -->
                <div class="ml-3 relative">
                  <!--
        Profile dropdown panel, show/hide based on dropdown state.
        
        Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
        Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
      -->
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <menu-icon :mobile-menu="mobileMenu" @toggle-menu="toggleMenu"></menu-icon>
          </div>
        </div>
      </div>
      <!--
  Mobile menu, toggle classes based on menu state.
  
  Open: "block", closed: "hidden"
-->
      <div v-if="mobileMenu.open" class="md:hidden" :class="mobileMenu.open ? 'block' : 'hidden'">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <router-link v-if="currentOrgType === 'owner'" to="/devices" class="mt-1 block">
            Devices
          </router-link>
          <router-link v-else-if="currentOrgType === 'consumer'" to="/users" class="mt-1 block">
            Users
          </router-link>
          <router-link to="/connections" class="mt-1 block">Connections</router-link>
          <router-link to="/events" class="mt-1 block">Events</router-link>
          <router-link to="/accounts" class="mt-1 block">Accounts</router-link>
          <router-link to="/downloads" class="mt-1 block">Downloads</router-link>
          <router-link to="/instructions" class="mt-1 block">Instructions</router-link>
          <router-link :to="`/configuration/${currentOrgId}`" class="mt-1 block">
            Configuration
          </router-link>
          <router-link
            v-if="currentOrgType === 'consumer'"
            to="/event-dispatcher"
            class="mt-1 block"
          >
            Dispatcher
          </router-link>
          <router-link
            v-if="currentOrgType === 'consumer'"
            to="/backend-subscriber-configuration"
            class="mt-1 block"
          >
            CAD Config
          </router-link>
          <router-link v-if="currentOrgType === 'owner'" to="/summary" class="mt-1 block">
            Summary
          </router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="mt-3 px-2">
            <router-link :to="`/accounts/${currentOrgId}`" class="mt-1 block">
              Your Account
            </router-link>
            <button
              class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white focus:outline-none focus:text-white logoutLink"
              type="button"
              @click.prevent="changeAccountPassword"
            >
              Change Password
            </button>

            <button
              class="mt-1 block p-2 text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              type="button"
              @click.prevent="logout()"
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="!loading">
      <banner-component :bannerEventList="bannerEvents"></banner-component>
    </div>
  </div>
</template>

<script>
import BannerComponent from './BannerComponent.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import * as Auth from '@/modules/authv2';

export default {
  name: 'MainNavigation',

  components: {
    MenuIcon,
    BannerComponent,
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentOrgType() {
      return this.$store.getters.currentUserOrgType;
    },
    currentOrgId() {
      return this.$store.getters.currentUserOrgId;
    },
    currentAccountId() {
      return this.$store.getters.currentUserAccountId;
    },
    currentUserName() {
      return this.$store.getters.currentUserName;
    },
    // Need to pass the whole config. Accessing variable inside config returns null.
    currentUserOrgConfig() {
      let userConfig = this.$store.getters.config;
      return userConfig?.Name;
    },
    currentUserIsDispatch() {
      return this.$store.getters.currentUserIsDispatcher;
    },
    currentUserIsImageViewer() {
      return this.$store.getters.currentUserIsImageViewer;
    },
    currentEmail() {
      if (
        this.$store.getters.currentUserClaim?.emails &&
        this.$store.getters.currentUserClaim?.emails.length
      ) {
        return this.$store.getters.currentUserClaim.emails[0];
      } else {
        return 'none';
      }
    },
    currentName() {
      return this.$store.getters.currentUser?.name;
    },
    supportPath() {
      return `/support?source=${this.$route.path}`;
    },
    portalType() {
      return this.currentUserIsImageViewer
        ? 'Image Viewer'
        : this.currentUserIsDispatch
        ? 'Dispatch'
        : this.currentOrgType + ' Administration';
    },
    getNonRmsEvents() {
      return this.$store.getters['nonRmsEvents']?.data ?? [];
    },
    bannerEvents() {
      return this.getNonRmsEvents.filter((event) => event.other.type == 'banner');
    },
  },

  data() {
    return {
      loading: true,
      dropdownMenu: {
        open: false,
        locked: false,
      },
      mobileMenu: {
        open: false,
      },
    };
  },

  methods: {
    toggleDropdownMenu() {
      this.dropdownMenu.open = !this.dropdownMenu.open;
    },

    openDropdownMenu() {
      this.dropdownMenu.open = true;
    },

    closeDropdownMenu() {
      if (!this.dropdownMenu.locked) {
        this.dropdownMenu.open = false;
      }
    },

    toggleMenu(mobileMenu) {
      this.mobileMenu = mobileMenu;
    },

    logout() {
      this.$store.dispatch('logoutCurrentUser');
    },

    openAndLockMenu() {
      this.dropdownMenu.locked = true;
      this.openDropdownMenu();
    },

    closeAndUnlockMenu() {
      this.dropdownMenu.locked = false;
      this.closeDropdownMenu();
    },

    changeAccountPassword() {
      Auth.changePassword();
    },

    updateNonRmsEvents() {
      this.loading = true;

      this.$store
        .dispatch('getEvents', {
          limit: 50,
          OtherType: ['banner', 'email', 'export'],
          filters: [],
        })
        .then((response) => {
          if (!response) {
            console.error('Error reading non rms events');
          }

          this.loading = false;
        })
        .catch((error) => {
          console.error('MainNavigation::updateNonRmsEvents() -- error:', error);

          this.loading = false;
        });
    },
  },

  watch: {
    /**
     * Close dropdownMenu when navigation changes
     *
     * Note that navigation only closes changes
     * when navigating to a new page.
     */
    $route() {
      this.dropdownMenu.open = false;
    },
  },

  beforeCreate() {
    this.$store.dispatch('getConfig', this.id);
  },

  mounted() {
    this.updateNonRmsEvents();

    let tourObj = {
      componentName: 'mainNavigation',
      newSteps: [
        {
          target: '.helpLink',
          content: `Welcome to the Sentry-SECURE Portal<br><br>
           Sentry-SECURE platform provides situational awareness for connected RMS Owner and Consumer Organizations in a cloud platform<br><br>
           Help prompts like this one are located on all tabs and will provide contextual information`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.devicesLink',
          content: `Manage Sentry-RMS units<br>
           View status<br>
           Provision new units<br>
           Change the configuration`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.appUsersLink',
          content: `Manage Mobile Application Users<br>
           View, Search, and Sort Users<br>
           Create New Users (Single or Bulk)<br>
           Change User Level Notification Filters<br>
           Generate Organization QR Code`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.connectionsLink',
          content: `Manage organization Connections<br>
                  Create, View and Edit<br>
                  Connections with other organizations`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.eventsLink',
          content: `Manage Events<br>
                  View, Search,<br>
                  Export and Delete`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.accountsLink',
          content: `Manage Accounts<br>
                  View, Edit and Add`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.kabobMenu',
          content: `Access additional screens`,
          params: {
            enableScrolling: false,
          },
          before: this.openAndLockMenu,
        },
        {
          target: '.downloadsLink',
          content: `Download and approve Event Exports`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.instructionsLink',
          content: `Add and Modify Instructions pertaining to Event types`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.trainingLink',
          content: `Generate Training Events for Owned or Connected Devices`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.nonRMSEventsLink',
          content: `Generate Banner and Email Events for Connected Accounts`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.configurationLink',
          content: `View and Modify Organization contact information, retention settings and notification filters`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.summaryLink',
          content: `View and Modify Organization Summary on the Site/Building/Room levels`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.dispatcherLink',
          content: `Dispatch Patrol Users to Events and Connections`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.cadLink',
          content: `Modify Templates for CAD Backend Subscribers`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.viewYourAccountLink',
          content: `View and Modify Contact Information for your Account`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.changePasswordLink',
          content: `Change the Password of the current Account`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.supportLink',
          content: `For additional help, click this link to send an email to SECURE help desk or use the SECURE Chat Bot`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.logoutLink',
          content: `Sign out of the current Account`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
        {
          target: '.welcomelink',
          content: `Click here to return to the Welcome screen`,
          params: {
            placement: 'bottom',
            enableScrolling: false,
          },
          before: this.closeAndUnlockMenu,
        },
      ],
    };

    let ownerHiddenList = ['.dispatcherLink', '.cadLink', '.appUsersLink'];

    let consumerHiddenList = ['.devicesLink', '.summaryLink'];

    let imageTourObj = {
      componentName: 'mainNavigation',
      newSteps: [
        {
          target: '.welcomelink',
          content: `Welcome to the Sentry-SECURE Portal<br><br>
              Sentry-SECURE platform provides situational awareness for connected RMS Owner and Consumer Organizations in a cloud platform<br><br>
              Help prompts like this one are located on all tabs and will provide contextual information`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.welcomelink',
          content: `The Image Viewer account only has access to view Event Media.<br><br>
              Copy the Event Media Link into the browser to view the media. The link will be provided by the organization admin.`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.logoutLink',
          content: `Sign out of the current Account`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
      ],
    };

    let dispatcherTourObj = {
      componentName: 'mainNavigation',
      newSteps: [
        {
          target: '.welcomelink',
          content: `Welcome to the Sentry-SECURE Portal<br><br>
              Sentry-SECURE platform provides situational awareness for connected RMS Owner and Consumer Organizations in a cloud platform<br><br>
              Help prompts like this one are located on all tabs and will provide contextual information`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.cadLink',
          content: `The Dispatcher account only has access to the Consumer Dispatcher tab.<br><br>
              Click here to switch to the Dispatcher Tab.<br><br>
              The Dispatcher tab allows Patrol Users to be dispatched to active Events or Connections`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.logoutLink',
          content: `Sign out of the current Account`,
          params: {
            placement: 'left',
            enableScrolling: false,
          },
        },
      ],
    };

    let orgType = this.$store.getters.currentUserOrgType;

    var checkHiddenList;

    switch (orgType) {
      case 'consumer':
        checkHiddenList = consumerHiddenList;
        break;
      case 'owner':
        checkHiddenList = ownerHiddenList;
        break;
    }

    var trimmedSteps = tourObj.newSteps;
    for (var i = 0; i < checkHiddenList.length; ++i) {
      for (var j = 0; j < trimmedSteps.length; ++j) {
        if (trimmedSteps[j].target == checkHiddenList[i]) {
          trimmedSteps.splice(j, 1);
        }
      }
    }
    if (this.$store.getters.currentUserIsImageViewer) {
      this.$store.commit('setTourStepsNav', imageTourObj.newSteps);
    } else if (this.$store.getters.currentUserIsDispatcher) {
      this.$store.commit('setTourStepsNav', dispatcherTourObj.newSteps);
    } else {
      this.$store.commit('setTourStepsNav', trimmedSteps);
    }
  },
};
</script>

<style></style>
