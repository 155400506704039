import { api } from "@/modules/apiv2";

export default {
  state: {
    subscriber: null,
    subscribers: { "BackendSubscriber": null, AppSubscribers: [] },
    connectedSubscribers: {},
  },

  getters: {
    subscriber(state) {
      return state.subscriber;
    },

    subscribers(state) {
      return state.subscribers;
    },

    connectedSubscribers(state) {
      return state.connectedSubscribers;
    }
  },

  mutations: {
    setSubscriber(state, subscriber) {
      state.subscriber = subscriber;
    },

    setSubscribers(state, subscribers) {
      state.subscribers = subscribers;
    },

    setConnectedSubscribers(state, connectedSubscribers) {
      state.connectedSubscribers = connectedSubscribers;
    }
  },

  actions: {
    getSubscriber({ commit }, subscriberId) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/subscribers/${subscriberId}`
        )
        .then((data) => {
          commit('setSubscriber', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getSubscribers({ commit }, connectionId) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/connections/${connectionId}/subscribers`
        )
        .then((data) => {
          commit('setSubscribers', data);
          return data;
        })
        .catch(() => {
          commit('setSubscribers', { "BackendSubscriber": null, AppSubscribers: [] });
        });
    },

    async populatedConnectedSubscribers({ commit }) {
      let connectedAppSub = { 'AppSubscribers': {} };
      connectedAppSub['RmsConsumers'] = {};
      connectedAppSub.RmsConsumers['All Consumers'] = [];

      if (this.getters['currentUserIsOwner']) {
        let owner = this.getters['config'];
        connectedAppSub['RmsOwners'] = {};
        connectedAppSub.RmsOwners['All Owners'] = [];
        connectedAppSub.RmsOwners['All Owners'].push(owner);

        let acceptedConnections = this.getters.connections.filter((c) => c.Status == 'Accepted');
        for (const connection of acceptedConnections) {
          await this.dispatch('getSubscribers', connection.id);
          let subscribers = this.getters['subscribers'];

          connectedAppSub.AppSubscribers[connection.ConsumerConnectionDetails.OrganizationName] = subscribers.AppSubscribers;
          connectedAppSub.RmsConsumers['All Consumers'].push({
            Name: connection.ConsumerConnectionDetails.OrganizationName,
            id: connection.ConsumerConnectionDetails.OrganizationId
          })
        }
      }

      if (this.getters['currentUserIsConsumer']) {
        let subscribers = this.getters['users'];
        let consumer = this.getters['config'];
        connectedAppSub.RmsConsumers['All Consumers'].push(consumer);
        connectedAppSub.AppSubscribers[consumer.Name] = subscribers;
      }

      commit('setConnectedSubscribers', connectedAppSub);
      return connectedAppSub;
    },
  },
};
