<template>
  <div class="h-screen">
    <h1 class="text-3xl sm:text-5xl text-center italic">
      <slot>Loading&hellip;</slot>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
