import * as Msal from "@azure/msal-browser";
import store from '@/store';


const tenantName = process.env.VUE_APP_MSAL_CONFIG_AUTH_TENANT_NAME;

const b2cPolicies = {
  names: {
    signIn: process.env.VUE_APP_MSAL_CONFIG_AUTH_SIGNIN,
    forgotPassword: process.env.VUE_APP_MSAL_CONFIG_AUTH_PWRESET,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${process.env.VUE_APP_MSAL_CONFIG_AUTH_SIGNIN}`,
    },
    forgotPassword: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${process.env.VUE_APP_MSAL_CONFIG_AUTH_PWRESET}`,
    }
  },
  authorityDomain:
    `${tenantName}.b2clogin.com`
};

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CONFIG_AUTH_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.VUE_APP_MSAL_CONFIG_AUTH_REDIRECT_URI,
    "validateAuthority": true
  },
  cache: {
    cacheLocation: process.env.VUE_APP_MSAL_CONFIG_CACHE_CACHE_LOCATION,
    storeAuthStateInCookie: process.env.VUE_APP_MSAL_CONFIG_CACHE_STORE_AUTH_STATE_IN_COOKIE === 'true',
  }
};

const msalObj = new Msal.PublicClientApplication(msalConfig);

const loginRequest = {
  scopes: ['openid', 'profile'],
};

let exceptionCallback = null;
let passwordChangedCallback = null;
let passwordChangeCallback = null;
let passwordChangeCanceledCallback = null;

export function getAccount() {
  let accountObj = null;
  const currentAccounts = msalObj.getAllAccounts();
  if (!currentAccounts || currentAccounts.length === 0) {
    // No user signed in
  } else if (currentAccounts.length > 1) {
    console.warn("More than one user signed in");
    // logout old user
    logout(currentAccounts[0]);
  } else {
    accountObj = currentAccounts[0];
  }
  return accountObj;
}

// Register Callbacks for Redirect flow

export var redirectHandledPromise = msalObj.handleRedirectPromise().then(async function redirect(tokenResponse) {
  if (tokenResponse === null) {
    // This is our first call on site build
    return;
  }

  let currentUser = null;
  let accountObj = null;

  if (tokenResponse !== null) {
    accountObj = tokenResponse.account;
    currentUser = tokenResponse;

    if (tokenResponse.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
      if (passwordChangedCallback) {
        passwordChangedCallback();
        return;
      }
    }
  }

  if (accountObj !== null) {
    if (currentUser === null) {
      var user = store.getters['currentUser'];
      if (user && user.idToken) {
        // Store currentUser looks legit, returning
        return;
      }
      // currentUser is not valid, acquiring token silent
      let initialTokenRequest = loginRequest;
      initialTokenRequest.account = accountObj;
      try {
        currentUser = await msalObj.acquireTokenSilent(initialTokenRequest)
      } catch (error) {
        console.warn("silent token acquisition failed.");
        console.warn(error);
        return null;
      }
    }
  }

  if (currentUser !== null) {
    store.commit('setCurrentUser', currentUser);
    store.dispatch('loginUser');
  }

}).catch(function redirectCatch(error) {
  // Check for forgot password error
  // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
  if (error.errorMessage.indexOf("AADB2C90118") > -1) {
    try {
      msalObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
    } catch (err) {
      console.error(err.message);
    }
  }
  else if (error.errorMessage.indexOf("AADB2C90091") > -1) {
    // User canceled the password change
    if (passwordChangeCanceledCallback) {
      passwordChangeCanceledCallback();
    }
  } else {
    console.error("Other login exception");
    if (exceptionCallback) {
      if (exceptionCallback(error)) {
        return;
      }
    }
    if (passwordChangeCanceledCallback) {
      passwordChangeCanceledCallback();
    }
    console.error(error.errorCode);
    window.alert("AAD B2C exeption, logging you out");
    msalObj.logout();
  }
});

export async function getTokenResponse(operationType) {

  try {
    const currentUserAccount = getAccount();
    let currentUser = store.getters['currentUser'];
    let scopes = null;

    if (currentUserAccount === null) {
      // Can't get token if not logged in, redirecting to login
      return await this.login();
    }

    if (!currentUser || !currentUser.idTokenClaims || !currentUser.idTokenClaims.extension_OrgType) {
      // no valid current user, using login scopes
      scopes = ['openid', 'profile'];
    } else {
      let orgType = currentUser.idTokenClaims.extension_OrgType;
      let userType = orgType.toLocaleLowerCase();
      let scopeUrl = "";

      if (userType.indexOf("owner") !== -1) {
        userType = 'owner';
        scopeUrl = process.env.VUE_APP_MSAL_CONFIG_AUTH_OWNER_API_SCOPE;
      }
      else if (userType.indexOf("consumer") !== -1) {
        userType = 'consumer';
        scopeUrl = process.env.VUE_APP_MSAL_CONFIG_AUTH_CONSUMER_API_SCOPE;
      }

      const scope = scopeUrl + userType + '.' + operationType;
      scopes = [scope];
    }

    const tokenRequest = {
      scopes: scopes,
      forceRefresh: false,
      account: currentUserAccount
    };
    // Getting token for scopes
    return await msalObj.acquireTokenSilent(tokenRequest)
      .catch(async () => {
        // silent token acquisition fails. acquiring token using redirect
        // fallback to interaction when silent call fails
        return await msalObj.acquireTokenRedirect(tokenRequest)
      });
  } catch (error) {
    console.error(error);
    return null;
  }

}

export async function getAccessToken(operationType) {
  const tokenResponse = await getTokenResponse(operationType);
  if (tokenResponse === null) {
    throw {
      status: "handleRequest failure",
      statusText: "Couldn't get currentUser with idToken",
    };
  }

  const token = tokenResponse.accessToken ? tokenResponse.accessToken :
    tokenResponse.idToken ? tokenResponse.idToken : null;

  return token;
}

export async function login() {
  try {
    return await msalObj.acquireTokenRedirect(loginRequest);
  } catch (error) {
    console.error(error);
    console.error("login in process, aborting");
  }
}

export async function logout(account = null) {

  if (account) {
    const logoutRequest = {
      account: account
    };

    msalObj.logout(logoutRequest);
  } else {
    msalObj.logout();
  }
}

export function changePassword() {
  return msalObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
}

export var setExceptionCallback = (callback) => {
  exceptionCallback = callback;
};

export var setPasswordChangedCallback = (callback) => {
  passwordChangedCallback = callback;
};

export var setPasswordChangeCanceledCallback = (callback) => {
  passwordChangeCanceledCallback = callback;
};

export var setForcePasswordChangeCallback = (callback) => {
  passwordChangeCallback = callback;
};

export var forcePasswordChange = () => {
  if (passwordChangeCallback) {
    passwordChangeCallback().then(() => {
      changePassword();
    });
  }
};

export var loggedInTest = () => {
  return (msalObj.getAllAccounts().length > 0);
};

/**
 * Get the current user.
 */
export var user = function () {
  return store.getters['currentUser'];
};

