const APP_API_PREFIX = process.env.VUE_APP_CONSUMER_API_PREFIX;

import { api } from "@/modules/apiv2";

export default {
  state: {
    backendConfig: null,
    proxyConfigThumbprint: ""
  },

  getters: {
    backendConfig(state) {
      return state.backendConfig;
    },
    proxyConfigThumbprint(state) {
      return state.proxyConfigThumbprint;
    }
  },

  mutations: {
    setBackendConfig(state, backendConfig) {
      state.backendConfig = backendConfig;
    },
    setProxyConfigThumbprint(state, proxyConfigThumbprint) {
      state.proxyConfigThumbprint = proxyConfigThumbprint;
    }
  },

  actions: {
    getBackendConfig({ commit }, template = null) {
      const currentUser = this.getters['currentUser'];

      let endpoint = `/${APP_API_PREFIX}/consumers/${currentUser.idTokenClaims.extension_OrgId}/backendsubscriber`;

      if (template) {
        endpoint = `${endpoint}s/${template}`;
      }

      return api
        .get(endpoint)
        .then((data) => {
          commit('setBackendConfig', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getProxyThumbprint({ commit }) {
      const currentUser = this.getters['currentUser'];

      let endpoint = `/${APP_API_PREFIX}/consumers/${currentUser.idTokenClaims.extension_OrgId}/proxy`;

      return api
        .get(endpoint)
        .then((data) => {
          commit('setProxyConfigThumbprint', data.Thumbprint);
          return data;
        })
        .catch((error) => {
          commit('setProxyConfigThumbprint', "");
          throw error;
        });
    },

    deleteProxyConfig({ commit }) {
      const currentUser = this.getters['currentUser'];

      let endpoint = `/${APP_API_PREFIX}/consumers/${currentUser.idTokenClaims.extension_OrgId}/proxy`;

      return api
        .delete(endpoint)
        .then((response) => {
          commit('setProxyConfigThumbprint', "");
          return response;
        })
        .catch((error) => {
          throw error;
        })
    },

    upsertProxyConfig({ commit }, filePassword) {
      const currentUser = this.getters['currentUser'];

      let queryParam = filePassword;

      let endpoint = `/${APP_API_PREFIX}/consumers/${currentUser.idTokenClaims.extension_OrgId}/proxy?password=${queryParam}`;

      return api
        .postFile(endpoint)
        .then((response) => {
          commit('setProxyConfigThumbprint', "");
          return response
        })
    },

    updateBackendConfig({ commit }, data) {
      const currentUser = this.getters['currentUser'];

      return api
        .post(
          `/${APP_API_PREFIX}/consumers/${currentUser.idTokenClaims.extension_OrgId}/backendsubscriber`,
          data
        )
        .then((data) => {
          commit('setBackendConfig', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
