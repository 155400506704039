export default {
  state: {
    tourSteps: [],
    tourStepsNav: [],
    tourStepsObjs: {},
  },

  getters: {
    tourSteps(state) {
      return state.tourSteps;
    },

    tourStepsNav(state) {
      return state.tourStepsNav;
    },
  },

  mutations: {
    addTourStepsObj(state, newStepsObj) {
      state.tourStepsObjs[newStepsObj.componentName] = newStepsObj.newSteps;
    },

    removeTourStepsObj(state, componentName) {
      state.tourStepsObjs[componentName] = [];
    },

    updateTourSteps(state) {
      const mainNavigation = "mainNavigation";
      let newTourSteps = [];
      
      for (let steps in state.tourStepsObjs) {
        if (steps === mainNavigation) continue;
        newTourSteps = newTourSteps.concat(state.tourStepsObjs[steps]);
      }

      if (mainNavigation in state.tourStepsObjs) {
        newTourSteps = newTourSteps.concat(state.tourStepsObjs[mainNavigation]);
      }

      state.tourSteps = newTourSteps;
    },
    
    clearTourSteps(state) {
      state.tourStepsObjs = {};
    },

    setTourStepsNav(state, navSteps) {
      state.tourStepsNav = navSteps;
    },
  },

  actions: {
    addTourSteps({ commit }, newStepsObj) {
      commit('addTourStepsObj', newStepsObj);
      commit('updateTourSteps');
    },

    removeTourSteps({ commit }, componentName) {
      commit('removeTourStepsObj', componentName);
      commit('updateTourSteps');
    }
  }
};
