const APP_API_PREFIX = process.env.VUE_APP_INFO_API_PREFIX;
const descriptionsKey = 'x-ss-descriptions'
const eventKeysKey = 'x-ss-eventKeys'
import { api } from "@/modules/apiv2";

let descriptionsDownloaded = false;

export default {
  state: {
    descriptions: JSON.parse(localStorage.getItem(descriptionsKey)),
    eventKeys: JSON.parse(localStorage.getItem(eventKeysKey)),
    ownerApiListData: null,
  },

  getters: {
    eventKeys(state) {
      return state.eventKeys;
    },

    descriptions(state) {
      return state.descriptions;
    },

    eventAlarms(state) {
      if (state.descriptions.DescriptionsByEventKey) {
        return Object.entries(state.descriptions.DescriptionsByEventKey)
          .filter((entry) => {
            return entry[1].StatusKey === 'Alarm';
          })
          .map((entry) => {
            return entry[0];
          });
      }

      return [];
    },


    eventAlerts(state) {
      if (state.descriptions.DescriptionsByEventKey) {
        return Object.entries(state.descriptions.DescriptionsByEventKey)
        .filter((entry) => {
          return entry[1].StatusKey === 'Alert';
        })
        .map((entry) => {
          return entry[0];
        });
      }

      return [];
    },

    eventOther(state) {
      if (state.descriptions.DescriptionsByEventKey) {
        return Object.entries(state.descriptions.DescriptionsByEventKey)
          .filter((entry) => {
            return entry[1].StatusKey !== 'Alarm' && entry[1].StatusKey !== 'Alert';
          })
          .map((entry) => {
            return entry[0];
          });
      }

      return [];
    },

    eventKeyDescription: (state) => (eventKey) => {
      try {
        return state.descriptions.DescriptionsByEventKey[eventKey].Description;
      } catch {
        return eventKey;
      }
    },
  },


  mutations: {
    setDescriptions(state, descriptions) {
      state.descriptions = descriptions;
    },

    setEventKeys(state, eventKeys) {
      state.eventKeys = eventKeys;
    },
  },

  actions: {
    getEventKeys({ commit }) {
      return (
        api
          .get(`/${APP_API_PREFIX}/keys`)
          .then((data) => {
            localStorage.setItem(eventKeysKey, JSON.stringify(data))
            commit('setEventKeys', data);
            return data;
          })
          .catch((error) => {
            throw error;
          })
      );
    },

    getDescriptions({ commit }) {

      if (descriptionsDownloaded) {
        return;
      }

      descriptionsDownloaded = true;

      return api
        .get(`/${APP_API_PREFIX}/descriptions`)
        .then((data) => {
          localStorage.setItem(descriptionsKey, JSON.stringify(data))
          commit('setDescriptions', data);

          return data;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
