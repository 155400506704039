<template>
  <div v-if="screenStatus === 'mainSite'" class="h-screen flex flex-col">
    <main-navigation v-on:start-tour="startTour()"></main-navigation>
    <div class="flex-1 overflow-y-auto">
      <div v-if="currentUser">
        <router-view v-if="loggedIn"></router-view>
        <loader v-else>
          <div class="whitespace-pre-line">{{ loadingMessage }}</div>
        </loader>
      </div>
      <footer class="p-6 mt-6"></footer>
    </div>
    <secure-chat-bot/>
    <v-tour name="AdminPortalTour" :steps="tourSteps"></v-tour>
    <v-idle class="hidden" @idle="onidle" :wait="idleWaitTime_sec" :duration="idleTimout_sec"></v-idle>
  </div>
  <div
  v-else-if="screenStatus === 'login'"
  class="h-screen flex flex-col justify-center items-center"
  >
  <h1>Sentry-SECURE</h1>
  <h4 class="w-2/4 text-center">The Sentry-RMS Communications and Response (Sentry-SECURE)
    Admin Portal is produced by the Office of Radiological Security within the United States
    Department of Energy and the Pacific Northwest National Laboratory, operated by Battelle
    Memorial Institute (Battelle). Data presented by the Sentry-SECURE Admin Portal shall be used
    to promote situational awareness of high-activity, radioactive material only. Neither the
    United States, the United States Department of Energy, nor any of their staff, nor Battelle,
    its staff and subcontractors, makes any warranty, expressed or implied, or assumes any legal
    liability or responsibility for the accuracy, completeness, or timeliness of any data transmitted,
    produced, or processed, or represents that its use would not infringe privately owned rights.</h4>
    <p class="btn cursor-pointer" v-on:click="login">Click Here to Accept and Login</p>
  </div>
  <div v-else class="h-screen flex flex-col justify-center items-center">

    <h1>Loading...</h1>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import MainNavigation from '@/components/MainNavigation.vue';
import SecureChatBot from '@/components/SecureChatBot.vue';
import * as Auth from "@/modules/authv2";
import * as Swal from "sweetalert2";

export default {
  name: 'App',
  components: {
    Loader,
    MainNavigation,
    SecureChatBot
},

  data() {
    return {
      screenStatus: 'loading',
      idleTimout_sec: 900,
      idleWaitTime_sec: 10,
      idleReminder_sec: 30,
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters['currentUser'];
    },

    loadingMessage() {
      let loggedInStatus = this.$store.getters['loggedInStatus'];
      if (loggedInStatus === 'loggedInFailed') {
        return (
          'You or another member of your organization with the same account type is already logged in on another window\n' +
          'Please logout there and login again or contact the administrator for your organization'
        );
      } else if (loggedInStatus === 'loggedInError') {
        return 'Error logging in\nPlease reload the page';
      } else {
        return 'Loading...';
      }
    },
    loggedIn() {
      return this.$store.getters['loggedInStatus'] === 'loggedIn';
    },
    tourSteps() {
      if (this.$route.name === 'welcome') {
        return this.$store.getters.tourStepsNav;
      } else {
        return this.$store.getters.tourSteps;
      }
    },
  },

  methods: {
    startTour() {
      this.$tours['AdminPortalTour'].start();
    },
    onidle() {
      let timerInterval;
      Swal
        .fire({
          title: 'Auto logout alert!',
          html: `You will be logged out in <b></b> seconds`,
          showConfirmButton: false,
          timerProgressBar: true,
          timer: this.idleReminder_sec * 1000,
          didOpen: () => {
            timerInterval = setInterval(() => {
              const content = Swal.getHtmlContainer();
              if (content) {
                const b = content.querySelector('b');
                if (b) {
                  b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
                }
              }
            }, 1000);
          },
          didClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result.dismiss === 'timer') {
            this.$store.dispatch('logoutCurrentUser');
          }
        });
    },

    login() {
      this.$store.dispatch('loginCurrentUser');
    },
  },

  beforeCreate() {
    if (window.location.href.includes('changepassword')) {
      Swal
        .fire({
          title: 'Reset Password?',
          text: 'Do you want to reset your password?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Reset Password',
          cancelButtonText: 'Return to Login Screen',
        })
        .then((result) => {
          if (result.isConfirmed) {
            Auth.changePassword();
          } else {
            this.login();
          }
        });
    }

    let redirectCallbackCalled = false;

    Auth.setExceptionCallback((error) => {
      redirectCallbackCalled = true;
      console.error(error);
      Swal
        .fire({
          title: 'Reset Password?',
          text: 'Do you want to reset your password?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Reset Password',
          cancelButtonText: 'Return to Login Screen',
        })
        .then((result) => {
          if (result.isConfirmed) {
            Auth.changePassword();
          } else {
            this.login();
          }
        });
      return true;
    });

    Auth.setPasswordChangedCallback(() => {
      redirectCallbackCalled = true;
      this.$store.commit('setPasswordChangeState', 'changed');
      Swal
        .fire({
          title: 'Password Changed',
          text: 'Please login with your new password',
          icon: 'success',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        })
        .then(() => {
          this.$store.dispatch('logoutCurrentUser');
        });
      return true;
    });

    Auth.setPasswordChangeCanceledCallback(() => {
      redirectCallbackCalled = true;
      this.$store.commit('deletePasswordChangeState');
      Swal
        .fire({
          title: 'Password Change canceled',
          text: 'Logging you out',
          icon: 'error',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        })
        .then(() => {
          this.$store.dispatch('logoutCurrentUser');
        });
      return true;
    });

    Auth.setForcePasswordChangeCallback(() => {
      return Swal.fire({
        title: 'You must change your password',
        icon: 'warning',
      });
    });

    this.$store.commit('clearTourSteps');

    Auth.redirectHandledPromise.then(() => {
      // the msal redirect has been handled
      // relavent error callbacks have been called

      // if they have been called redirectCallbackCalled will be true
      if (redirectCallbackCalled) {
        // if any of them have been called, we don't need to do anything here
        // because all of the callbacks redirect back to b2c user flows
        return;
      }

      // two paths from here
      // either we are logged in to b2c or we are not
      let loggedIn = Auth.loggedInTest();
        // if we are logged
      if (loggedIn) {
        // we make sure we have a token for the api
        this.$store
          .dispatch('updateCurrentUser')
          .then(() => {
            // We show the main site
            this.screenStatus = 'mainSite';
            // we get the descriptions
            return this.$store.dispatch('getDescriptions');
          })
          .then(() => {
            //we get the event keys
            return this.$store.dispatch('getEventKeys');
          });
      } else {
        // We are not logged in, show the login prompt
        this.screenStatus = 'login';
      }
      this.redirectsComplete = true;
    });
  },
};
</script>

<style src="@/assets/css/app.css"></style>
