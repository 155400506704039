<!--
FloatingChat.vue

a vue port of:
https://github.com/mishushakov/floating-chat/blob/master/src/FloatingChat.svelte

Generated by ChatGPT

Copyright 2023 VPI Technology
All rights reserved
-->

<template>
    <button :title="opened ? textclose : textopen" :aria-label="opened ? textclose : textopen"
        :class="['floating-chat', opened ? 'floating-chat-opened' : 'floating-chat-closed']" :style="buttonStyle"
        @click="toggle">
        <div class="floating-chat-text">{{ opened ? textclose : textopen }}</div>
        <div class="floating-chat-content">
            <iframe :src="content" :title="textopen" allow="microphone *"></iframe>
        </div>
    </button>
</template>
  
<script>
import { ref, computed } from 'vue';

export default {
    name: 'FloatingChat',
    props: {
        content: String,
        height: { type: String, default: '600px' },
        width: { type: String, default: '400px' },
        textopen: { type: String, default: 'Open' },
        textclose: { type: String, default: 'Close' },
        textcolor: { type: String, default: '#000000' },
        background: { type: String, default: '#FFFFFF' },
        logo: String,
        position: { type: String, default: 'bottom right' },
        font: String,
        borderradius: { type: String, default: '28px' }
    },
    setup(props, { emit }) {
        const opened = ref(false);

        const positiony = computed(() => props.position.split(' ')[0] === 'top' ? 'initial' : '0');
        const positionx = computed(() => props.position.split(' ')[1] === 'left' ? 'initial' : '0');

        const buttonStyle = computed(() => ({
            '--height': props.height,
            '--width': props.width,
            '--textcolor': props.textcolor,
            '--background': props.background,
            '--borderradius': props.borderradius,
            '--font': props.font,
            '--logo': props.logo,
            '--positiony': positiony.value,
            '--positionx': positionx.value
        }));

        function open() {
            opened.value = true;
            emit('open');
        }

        function close() {
            opened.value = false;
            emit('close');
        }

        function toggle() {
            opened.value ? close() : open();
            emit('toggle');
        }

        return { opened, buttonStyle, open, close, toggle };
    }
};
</script>


<style>
.floating-chat {
    padding: 0;
    border: none;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    font-family: var(--font), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: var(--background);
    border-radius: var(--borderradius);
    cursor: pointer;
    transition: all .45s cubic-bezier(.4, 0, .2, 1);
    position: fixed;
    bottom: var(--positiony);
    right: var(--positionx);
    margin: 16px;
    display: flex;
    flex-direction: column;
    z-index: 999
}

.floating-chat-text {
    min-width: 56px;
    color: var(--textcolor);
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    padding: 0 24px 0 0;
    font-size: .875rem;
    height: 48px
}

.floating-chat-text:before {
    min-width: 56px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    background-image: var(--logo);
    content: ''
}

.floating-chat:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149)
}

.floating-chat:not(.floating-chat-closed) {
    border-radius: calc(var(--borderradius) / 2)
}

.floating-chat:not(.floating-chat-closed)>.floating-chat-text:before {
    background: var(--textcolor);
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>') no-repeat center;
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>') no-repeat center
}

.floating-chat-content {
    display: flex;
    align-self: flex-end;
    height: var(--height);
    width: var(--width);
    transition: all .45s cubic-bezier(.4, 0, .2, 1);
    opacity: 1
}

.floating-chat:not(.floating-chat-closed)>.floating-chat-content {
    padding-bottom: 16px;
}

.floating-chat-content iframe {
    height: 100%;
    width: 100%;
    border: none;
}

.floating-chat-closed>.floating-chat-content {
    width: 0;
    height: 0;
    opacity: 0
}

@media screen and (max-width: 720px) {
    .floating-chat:not(.floating-chat-closed) {
        margin: 0px;
        border-radius: 0px
    }

    .floating-chat:not(.floating-chat-closed)>.floating-chat-content {
        width: 100vw;
        max-height: 100vh;
        height: calc(100vh - 56px);
        padding-bottom: 0px
    }

    .floating-chat-text {
        padding: 0;
        height: 56px;
        font-size: 0;
    }
}
</style>
