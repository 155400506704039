// const days_seconds = 60 * 60 * 24;

import { api } from "@/modules/apiv2";

export default {
  state: {
    config: null,
  },

  getters: {
    config(state) {
      return state.config;
    },
  },

  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
  },

  actions: {
    async getConfig({ commit }) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      let data = await api
        .get(`${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/config`);
      commit('setConfig', data);
    },

    updateConfig({ commit }, data) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .put(`${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/config`, data)
        .then((data) => {
          commit('setConfig', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
