import { createStore } from 'vuex';

import accounts from '@/store/modules/accounts';
import backendSubscribers from '@/store/modules/backendSubscribers';
import configs from '@/store/modules/configs';
import connections from '@/store/modules/connections';
import tourSteps from '@/store/modules/tourSteps';
import currentUser from '@/store/modules/currentUser';
import descriptions from '@/store/modules/descriptions';
import devices from '@/store/modules/devices';
import dispatches from '@/store/modules/dispatches';
import downloads from '@/store/modules/downloads';
import events from '@/store/modules/events';
import instructions from '@/store/modules/instructions';
import subscribers from '@/store/modules/subscribers';
import summary from '@/store/modules/summary';
import users from '@/store/modules/users';
import webchat from '@/store/modules/webchat';

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
  modules: {
    accounts,
    backendSubscribers,
    configs,
    connections,
    tourSteps,
    currentUser,
    descriptions,
    devices,
    dispatches,
    downloads,
    events,
    instructions,
    subscribers,
    summary,
    users,
    webchat,
  },
});
