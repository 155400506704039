import { api } from "@/modules/apiv2";

export default {
  state: {
    connection: null,
    connections: [],
    pairingCode: null,
    connectedOwners: []
  },

  getters: {
    connection(state) {
      return state.connection;
    },

    connections(state) {
      return state.connections;
    },

    pairingCode(state) {
      return state.pairingCode;
    },

    connectedOwners(state) {
      return state.connectedOwners
    }
  },

  mutations: {
    setConnection(state, connection) {
      state.connection = connection;
    },

    setConnections(state, connections) {
      state.connections = connections;
    },

    setPairingCode(state, pairingCode) {
      state.pairingCode = pairingCode;
    },

    setConnectedOwners(state, connectedOwners) {
      state.connectedOwners = connectedOwners;
    }
  },

  actions: {
    getConnection({ commit }, connectionId) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .get(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/connections/${connectionId}`
        )
        .then((data) => {
          commit('setConnection', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getConnections({ commit }) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .get(`${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/connections`)
        .then((data) => {
          commit('setConnections', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    createConnection({ commit }, data) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .post(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/connections?pairingCode=${data.PairingCode}`
        )
        .then((data) => {
          commit('setConnection', data.connection);
          return data.connection;
        })
        .catch((error) => {
          throw error;
        });
    },

    updateConnection({ commit }, data) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];
      const orgType = ((baseUrl.indexOf("owner") !== -1) ? "owner" : "consumer");

      return api
        .put(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/connections/${data.id}/${orgType}details`,
          data
        )
        .then((data) => {
          commit('setConnection', data.connection);
          return data.connection;
        })
        .catch((error) => {
          throw error;
        });
    },

    deleteConnection({ commit }, connectionId) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .delete(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/connections/${connectionId}`
        )
        .then(() => {
          commit('setConnection', null);
          return;
        })
        .catch((error) => {
          throw error;
        });
    },

    getPairingCode({ commit }) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .get(`${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/pairingcode`)
        .then((data) => {
          commit('setPairingCode', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    async getConnectedOwners({ commit, dispatch }) {
      let owners = []
      // get all Accepted connections 
      let connections = (await dispatch('getConnections')).filter((c) => c.Status == 'Accepted');

      // for each connection, get devices
      for (let connection of connections) {
        let devices = await dispatch('getConnectionDevices', connection.id)
        owners.push({
          config: {
            id: connection.OwnerConnectionDetails.OrganizationId,
            Name: connection.OwnerConnectionDetails.OrganizationName
          },
          devices: devices
        })
      }
      commit('setConnectedOwners', owners);
    }
  },
};
