import { api } from "@/modules/apiv2";

export default {
  state: {
    account: {ContactInformation: {}},
    accounts: [],
    canMakePatrol: false,
  },

  getters: {
    account(state) {
      return state.account;
    },

    accounts(state) {
      return state.accounts;
    },

    canMakePatrol(state) {
      return state.canMakePatrol;
    }
  },

  mutations: {
    setAccount(state, account) {
      state.account = account;
    },

    setAccounts(state, accounts) {
      state.accounts = accounts;
      for (let account of state.accounts) {
        if (account.AccountType === "Dispatcher") {
          state.canMakePatrol = true;
          return;
        }
      }
      state.canMakePatrol = false;
    },
  },

  actions: {
    getAccount({ commit }, accountId) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .get(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/accounts/${accountId}`
        )
        .then((data) => {
          commit('setAccount', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getAccounts({ commit }) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .get(`${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/accounts`)
        .then((data) => {
          commit('setAccounts', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    createAccount({ commit }, data) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .post(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/accounts`,
          data
        )
        .then((response) => {
          commit('setAccount', response);
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },

    updateAccount({ commit }, data) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .put(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/accounts/${data.id}`,
          data
        )
        .then((data) => {
          commit('setAccount', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    deleteAccount({ commit }, id) {
      const currentUser = this.getters['currentUser'];
      const baseUrl = this.getters['baseUrl'];

      return api
        .delete(
          `${baseUrl}/${currentUser.idTokenClaims.extension_OrgId}/accounts/${id}`
        )
        .then(() => {
          commit('setAccount', null);
          return;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
