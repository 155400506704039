import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import store from '@/store';

const DEFAULT_TITLE = 'Sentry-SECURE Admin Portal';


const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: () => import(/* webpackChunkName: "devices" */ '../views/Welcome.vue'),
      meta: { title: `Welcome | ${DEFAULT_TITLE}`},
    },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: () => import(/* webpackChunkName: "devices" */ '../views/Welcome.vue'),
      meta: { title: `Welcome | ${DEFAULT_TITLE}`},
    },
    {
      path: '/support',
      name: 'support',
      component: () => import(/* webpackChunkName: "devices" */ '../views/Support.vue'),
      meta: { title: `Support | ${DEFAULT_TITLE}`},
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import(/* webpackChunkName: "devices" */ '../views/Devices/Index.vue'),
      meta: { title: `Devices | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, ownerOnly, blockImageViewer] },
    },
    {
      path: '/devices/add',
      name: 'add-device',
      component: () => import(/* webpackChunkName: "devices" */ '../views/Devices/Create.vue'),
      meta: { title: `Add Device | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, ownerOnly, blockImageViewer]  },
    },
    {
      path: '/devices/:id',
      name: 'device-detail',
      props: true,
      component: () => import(/* webpackChunkName: "devices" */ '../views/Devices/Detail.vue'),
      meta: { title: `Device Detail | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/devices/:id/edit',
      name: 'device-edit',
      props: true,
      component: () => import(/* webpackChunkName: "devices" */ '../views/Devices/Form.vue'),
      meta: { title: `Device Edit | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, ownerOnly, blockImageViewer] },
    },
    {
      path: '/connections',
      name: 'connections',
      component: () =>
        import(/* webpackChunkName: "connections" */ '../views/Connections/Index.vue'),
      meta: { title: `Connections | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/connections/create',
      name: 'connection-create',
      component: () =>
        import(/* webpackChunkName: "connections" */ '../views/Connections/Create.vue'),
      meta: { title: `Connection Create | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/connections/:id',
      name: 'connection-detail',
      props: true,
      component: () =>
        import(/* webpackChunkName: "connections" */ '../views/Connections/Detail.vue'),
      meta: { title: `Connection Detail | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/connections/:id/edit',
      name: 'connection-edit',
      props: true,
      component: () =>
        import(/* webpackChunkName: "connections" */ '../views/Connections/Form.vue'),
      meta: { title: `Connection Edit | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/events',
      name: 'events',
      props: true,
      component: () => import(/* webpackChunkName: "events" */ '../views/Events/Index.vue'),
      meta: { title: `Events | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/events/:id',
      name: 'event-detail',
      props: true,
      component: () => import(/* webpackChunkName: "events" */ '../views/Events/Detail.vue'),
      meta: { title: `Event Detail | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts/Index.vue'),
      meta: { title: `Accounts | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/accounts/add',
      name: 'add-account',
      component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts/Create.vue'),
      meta: { title: `Add Account | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/accounts/:id',
      name: 'account-detail',
      props: true,
      component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts/Detail.vue'),
      meta: { title: `Account Detail | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/accounts/:id/edit',
      name: 'account-edit',
      props: true,
      component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts/Form.vue'),
      meta: { title: `Account Edit | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/instructions',
      name: 'instruction-edit',
      component: () =>
        import(/* webpackChunkName: "instructions" */ '../views/Instructions/Form.vue'),
      meta: { title: `Instructions Edit | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/training',
      name: 'training-create',
      component: () =>
        import(/* webpackChunkName: "training" */ '../views/Training/Create.vue'),
      meta: { title: `Training Create | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/nonRMSEvents',
      name: 'non-rms-event-create',
      props: true,
      component: () => import(/* webpackChunkName: "nonRMSEvents" */ '../views/nonRMSEvents/Create.vue'),
      meta: { title: `Banner and Emails | ${DEFAULT_TITLE}` },
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: () => import(/* webpackChunkName: "downloads" */ '../views/Downloads/Index.vue'),
      meta: { title: `Downloads | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/configuration/:id',
      name: 'configuration-detail',
      props: true,
      component: () =>
        import(/* webpackChunkName: "configuration" */ '../views/Configuration/Detail.vue'),
      meta: { title: `Configuration Detail | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/configuration/:id/edit',
      name: 'configuration-edit',
      component: () =>
        import(/* webpackChunkName: "configuration" */ '../views/Configuration/Form.vue'),
      meta: { title: `Configuration Edit | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/media/owners/:ownerId/devices/:deviceId',
      name: 'media-live-feed',
      props: true,
      component: () => import(/* webpackChunkName: "media" */ '../views/Media/LiveFeed.vue'),
      meta: { title: `Device Live Feed | ${DEFAULT_TITLE}`, middleware: [dispatcherRules]  },
    },
    {
      path: '/summary',
      name: 'summary',
      component: () => import(/* webpackChunkName: "summary" */ '../views/Summary/Form.vue'),
      meta: { title: `Summary | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, ownerOnly, blockImageViewer] },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ '../views/Users/Index.vue'),
      meta: { title: `Users | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, consumerOnly, blockImageViewer] },
    },
    {
      path: '/proxy',
      name: 'proxy',
      component: () => import(/* webpackChunkName: "users" */ '../views/Proxy/Form.vue'),
      meta: { title: `Proxy | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, consumerOnly, blockImageViewer] },
    },
    {
      path: '/users/subgroups',
      name: 'user-subgroups',
      component: () => import(/* webpackChunkName: "users" */ '../views/Users/Subgroups.vue'),
      meta: { title: `Sub Group Management | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, consumerOnly, blockImageViewer] },
    },
    {
      path: '/users/:id',
      name: 'edit-user',
      props: true,
      component: () => import(/* webpackChunkName: "users" */ '../views/Users/Form.vue'),
      meta: { title: `Edit User | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, blockImageViewer]  },
    },
    {
      path: '/backend-subscriber-configuration',
      name: 'backend-subscriber-edit-configuration',
      component: () =>
        import(/* webpackChunkName: "users" */ '../views/BackendSubcriberConfiguration/Form.vue'),
      meta: { title: `Computer Aided Dispatch Configuration | ${DEFAULT_TITLE}`, middleware: [dispatcherRules, consumerOnly, blockImageViewer] },
    },
    {
      path: '/event-dispatcher',
      name: 'event-dispatcher',
      component: () => import(/* webpackChunkName: "users" */ '../views/Dispatch/Dispatcher.vue'),
      meta: { title: `Event Dispatcher | ${DEFAULT_TITLE}`, middleware: [consumerOnly, blockImageViewer] },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import(/* webpackChunkName: "errors" */ '../views/Error404.vue'),
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import(/* webpackChunkName: "errors" */ '../views/Error404.vue'),
    }
  ],
});

function dispatcherRules ( store )
{
  return !(store.getters.currentUserAccountType === "Dispatcher")
}

function blockImageViewer ( store )
{
  return !(store.getters.currentUserAccountType === "ImageViewer")
}

function ownerOnly ( store )
{
  return store.getters.currentUserIsOwner;
}

function consumerOnly ( store )
{
  return store.getters['currentUserIsConsumer']
}

router.beforeResolve(async (to, _, next) => {

  if (!to.meta.middleware) {
    return next();
  }

  // currentUser is updated asynchronously, so
  // in order to have the necessary user information to
  // use the middleware, we need to wait for the information
  // to be there.
  await store.dispatch('updateCurrentUser');
  for (let cb of to.meta.middleware) {
    if (!cb(store)) {
      return next({
        name: '404'
      })
    }

  }
  return next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
