import { api } from "@/modules/apiv2";
import { isEqual } from 'lodash';
// This lazy loads the dependencies so that they are not downloaded with the main bundle
const jszip = () => import('jszip');
const aes = () => import('crypto-js/aes');
const enc = () => import('crypto-js/enc-utf8');

const fileName = 'SECURE_export.txt';

async function createZipFileFromString(input, password) {
  const JSZip = await jszip();
  const AES = await aes();
  const zip = new JSZip.default();

  let encryptedInstructions = AES.encrypt(input, password).toString();
  try {
    zip.file(fileName, `${encryptedInstructions}`);
  } catch (e) {
    console.error('error creating zip', e);
  }
  return await zip.generateAsync({ type: 'blob' });
}

async function createStringFromZipFile(instructionZipFile) {
  const JSZip = await jszip();
  let zip = new JSZip.default();
  let zipFile = await zip.loadAsync(instructionZipFile);
  return await zipFile.files[fileName].async('string');
}

function EmptyContact() {
  return {
    Name: '',
    Position: '',
    Organization: '',
    Address: '',
    PrimaryPhone: '',
    PrimaryPhoneIsMobile: false,
    SecondaryPhone: '',
    SecondaryPhoneIsMobile: false,
    Email: '',
    Website: ''
  };
}

function EmptyInstruction(eventKey = '') {
  return {
    id: '',
    OrgId: '',
    EventKey: eventKey,
    Text: '',
    Image: '',
    ImageCaption: '',
    Contacts: [new EmptyContact()],
  };
}

function copyInstruction(instruction) {
  return JSON.parse(JSON.stringify(instruction));
}

function addMissingContactsTo(instructionData) {
  // Contacts needed for form to render properly and be reactive
  if (!Object.prototype.hasOwnProperty.call(instructionData, 'Contacts')) {
    instructionData.Contacts = [new EmptyContact()];
  }
}

function removeEmptyContactsFrom(instructionData) {
  // Remove empty contacts before committing data
  instructionData.Contacts = instructionData.Contacts.filter((element) => !isEqual(element, EmptyContact()));
}

export default {
  state: {
    instruction: new EmptyInstruction(),
    instructionImageType: null,
    connectionInstruction: {},
    instructions: {},
    modified: new Set(),
    currentEventKey: '',
    debugObject: {},
  },

  getters: {
    instruction(state) {
      return state.instruction;
    },

    instructionImageType(state) {
      return state.instructionImageType;
    },

    connectionInstruction(state) {
      return state.connectionInstruction;
    },

    instructions(state) {
      return state.instructions;
    },

    debugObject(state) {
      return state.debugObject;
    },

    modified(state) {
      return state.modified;
    },

    emptyInstruction() {
      return new EmptyInstruction();
    },
  },

  mutations: {
    setInstruction(state, instruction) {
      state.instruction = instruction;
    },

    setInstructionImageType(state, instructionImageType) {
      state.instructionImageType = instructionImageType;
    },

    setConnectionInstruction(state, instruction) {
      state.connectionInstruction = instruction;
    },

    setInstructions(state, instructions) {
      state.instructions = instructions;
    },

    addInstruction(state, payload) {
      state.instructions[payload.EventKey] = payload;
    },

    addInstructionFromServer(state, payload) {
      state.instructions[payload.EventKey] = payload;
      state.modified.delete(payload.EventKey);
    },

    addModifiedField(state, payload) {
      const localEventKey = state.instruction.EventKey;
      if (state.instructions[localEventKey][payload.field] &&
        payload.newVal !== state.instructions[localEventKey][payload.field]) {
        state.modified.add(localEventKey);
      }
    },

    addModified(state, eventKey) {
      state.modified.add(eventKey);
    },

    clearModified(state) {
      state.modified.clear()
    },

    setDebugObject(state, instructions) {
      state.debugObject = instructions;
    },

    setInstructionId(state, id) {
      state.instruction.id = id;
    },

    setInstructionOrgId(state, id) {
      state.instruction.OrgId = id;
    },

    setInstructionEventKey(state, eventKey) {
      state.instruction.EventKey = eventKey;
    },

    setInstructionText(state, text) {
      state.instruction.Text = text;
    },

    setInstructionImage(state, image) {
      state.instruction.Image = image;
    },

    setInstructionImageCaption(state, caption) {
      state.instruction.ImageCaption = caption;
    },

    updateContact(state, { index, field, value }) {
      state.instruction.Contacts[index][field] = value;
    },

    addEmptyContact(state) {
      state.instruction.Contacts.add(new EmptyContact());
    },

    clearInstruction(state) {
      state.instruction = new EmptyInstruction();
    },

    clearConnectionInstructions(state) {
      state.connectionInstruction = new EmptyInstruction();
    },

    addMissingContactsToStore(state, eventKey) {
      addMissingContactsTo(state.instructions[eventKey]);
    }
  },

  actions: {
    getInstruction({ state, dispatch, commit }, eventKey) {
      if (eventKey in state.instructions) {
        dispatch('updateStoreInstruction', state.instructions[eventKey]);
        commit('addMissingContactsToStore', eventKey);
        return state.instructions[eventKey];
      }
      else {
        return dispatch('getInstructionFromApi', eventKey)
          .then((data) => {
            addMissingContactsTo(data);
            dispatch('updateStoreInstruction', data);
            commit('addInstructionFromServer', data);
            return data;
          }
          );
      }
    },

    getInstructionFromApi({ rootGetters }, eventKey) {
      const baseUrl = rootGetters.baseUrlOrg;
      const currentUserOrgType = rootGetters.currentUserOrgType;

      return api
        .get(`${baseUrl}/instructions/${eventKey}`)
        .then((data) => {
          if (currentUserOrgType === 'owner') {
            return data;
          } else if (currentUserOrgType === 'consumer') {
            return data.ConsumerInstructions;
          }
          return data;
        })
        .catch((error) => {
          if (error.status === 404) {
            return new EmptyInstruction(eventKey);
          }
          throw error;
        });
    },

    getInstructionExistence({ commit, rootGetters }, eventKey) {
      const baseUrl = rootGetters.baseUrlOrg;
      commit('setDebugObject', eventKey);

      return api
        .get(`${baseUrl}/instructions/${eventKey}`)
        .then((data) => {
          return data;
        })
        .catch(() => {
          return null;
        });
    },

    getConnectionInstruction({ commit, rootGetters }, options) {
      let connectionId = options.connectionId;
      let eventKey = options.eventKey;

      const baseUrl = rootGetters.baseUrlOrg;

      return api
        .get(`${baseUrl}/connections/${connectionId}/instructions/${eventKey}`)
        .then((data) => {
          commit('setConnectionInstruction', data);
          return data;
        })
        .catch((error) => {
          if (error.status === 404) {
            console.log(`Owner instructions does not exist`)
            return;
          }
          console.error(`Error downloading connection instructions `, error);
          throw error;
        });
    },

    async getAllInstructions({ dispatch, state, commit, rootGetters }, securityString) {
      const localInstructions = state.instructions;
      const exportEventKeys = rootGetters.eventKeys.EventKeysList;
      let collectedData = [];
      let imagePromises = [];
      let instructionExport = {};
      for (let k of exportEventKeys) {
        // Check to see if instruction has already been fetched
        if (k in localInstructions) {
          instructionExport[k] = { ...localInstructions[k] };
          delete instructionExport[k].id;
          delete instructionExport[k].OrgId;
          let imageURL = instructionExport[k].Image;
          imagePromises.push(dispatch('convertImageURLToBase64', imageURL).then((dataString) => {
            instructionExport[k].Image = dataString;
          }));
        } else {
          // fetch instruction if it hasn't
          collectedData.push(dispatch('getInstructionFromApi', k));
        }
      }

      let instructions = [];
      // Wait for all API calls
      await Promise.allSettled(imagePromises);
      instructions = await Promise.allSettled(collectedData);

      imagePromises = []
      for (let instruction of instructions) {
        // Only include if the data was received from the server
        if (!isEqual(instruction.value, EmptyInstruction(instruction.value.EventKey))) {
          let eventIdKey = instruction.value.EventKey;
          instructionExport[eventIdKey] = { ...instruction.value };
          commit('addInstructionFromServer', instruction.value);
          delete instructionExport[eventIdKey].id;
          delete instructionExport[eventIdKey].OrgId;
          let imageURL = instructionExport[eventIdKey].Image;

          imagePromises.push(dispatch('convertImageURLToBase64', imageURL)
            .then((value) => {
              instructionExport[eventIdKey].Image = value;
            })
          );
        }
      }

      await Promise.allSettled(imagePromises);

      let exportFileString = JSON.stringify(instructionExport);
      let zipFile = await createZipFileFromString(exportFileString, securityString);
      return zipFile;
    },

    async saveInstructionsToServer({ dispatch, state, commit }) {
      let localInstructions = state.instructions;
      let promises = [];
      commit('clearModified');
      for (let key in localInstructions) {
        promises.push(dispatch('updateInstruction', copyInstruction(localInstructions[key])));
      }
      await Promise.all(promises);
    },

    async convertImageURLToBase64({ commit }, imageURL) {
      commit('setDebugObject', imageURL);
      let blob = await fetch(imageURL).then((r) => r.blob());
      let dataUrl = await new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
      return dataUrl;
    },

    async convertBase64ToImageURL({ dispatch }, imageBase64) {
      try {
        let dataBuffer = await fetch(imageBase64);
        let dataBlob = await dataBuffer.blob()
        let imageUrl = await dispatch('uploadImageToOrg', dataBlob);
        return imageUrl;
      } catch (e) {
        console.error('error converting image', e);
      }
    },

    async uploadImageToOrg({ commit, rootGetters }, sourceImage) {
      const currentUser = rootGetters.currentUser;
      const currentUserOrgType = rootGetters.currentUserOrgType;
      const baseUrl = rootGetters.baseUrlOrg;
      commit('setDebugObject', baseUrl);

      return api
        .post(`/media/${currentUserOrgType}s/${currentUser.idTokenClaims.extension_OrgId}/files`)
        .then((response) => {
          const downloadUri = response.downloadUri;

          return fetch(response.uri, {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Length': sourceImage.size,
              'Content-Type': sourceImage.type,
              'x-ms-blob-type': 'BlockBlob',
            },
            body: sourceImage,
          })
            .then(() => {
              return downloadUri;
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async convertImportToInstructions({ dispatch, commit, rootGetters }, instructionFileAndPass) {
      const AES = await aes();
      const ENC = await enc();
      let instructions = "";

      const currentUser = rootGetters.currentUser;
      try {
        let encryptedInstructions = await createStringFromZipFile(instructionFileAndPass.file);
        let decryptedInstructions = AES.decrypt(
          encryptedInstructions,
          instructionFileAndPass.passcode
        ).toString(ENC);

        instructions = JSON.parse(decryptedInstructions);
      } catch (error) {
        console.error("Failed to import instructions", error);
      }

      let convertedPromises = [];

      let importedKeys = []
      for (let key in instructions) {
        let instruction = instructions[key];
        let verifiedInstruction = {};
        verifiedInstruction.id = `${currentUser.idTokenClaims.extension_OrgId}-${instruction.EventKey}`;
        verifiedInstruction.OrgId = currentUser.idTokenClaims.extension_OrgId;
        verifiedInstruction.EventKey = instruction.EventKey;
        verifiedInstruction.Text = instruction.Text;
        addMissingContactsTo(instruction);
        verifiedInstruction.Contacts = instruction.Contacts
        convertedPromises.push(dispatch('convertBase64ToImageURL', instruction.Image).then((value) => {
          verifiedInstruction.Image = value;
          verifiedInstruction.ImageCaption = instruction.ImageCaption;
          commit('addInstruction', verifiedInstruction);
          // Track instruction as modified since it may not align with that on the server
          commit('addModified', verifiedInstruction.EventKey);
          importedKeys.push(key);
        }));
      }
      await Promise.allSettled(convertedPromises);
      return importedKeys;
    },

    updateStoreInstruction({ commit, dispatch }, instruction) {
      commit('setInstruction', instruction);
      dispatch('updateInstructionImageType');
    },

    updateInstructionImageType({ state, commit }) {
      var imageUrl = state.instruction.Image;
      if (!imageUrl) {
        // Instructions don't have a valid Image URL
        return null;
      }

      fetch(imageUrl, { method: 'GET', })
        .then((response) => {
          // Check if the response is a redirect
          if (response.redirected) {
            // Manually follow the redirection
            return fetch(response.url, {
              method: 'HEAD',
            });
          } else {
            return response;
          }
        })
        .then((response) => {
          const type = response.headers.get('content-type');
          commit('setInstructionImageType', type);
        });
    },

    async updateInstruction({ commit, rootGetters }, data) {
      // Function expects a non-store instruction that can be modified before updating the server
      const baseUrl = rootGetters.baseUrlOrg;
      removeEmptyContactsFrom(data);
      return api
        .put(`${baseUrl}/instructions/${data.EventKey}`, data)
        .then((apiData) => {
          commit('addInstructionFromServer', apiData);
          return apiData;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
