const APP_API_PREFIX = process.env.VUE_APP_OWNER_API_PREFIX;

import { api } from "@/modules/apiv2";

export default {
  state: {
    device: null,
    devices: [],
  },

  getters: {
    device(state) {
      return state.device;
    },

    devices(state) {
      return state.devices;
    },
  },

  mutations: {
    setDevice(state, device) {
      state.device = device;
    },

    setDevices(state, devices) {
      state.devices = devices;
    },
  },

  actions: {
    getDevice({ commit }, deviceId) {
      const currentUser = this.getters['currentUser'];

      return api
        .get(
          `/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/devices/${deviceId}`
        )
        .then((data) => {
          commit('setDevice', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getDevices({ commit, getters, dispatch }) {
      let currentUser = getters.currentUser

      if (getters.currentUserIsOwner) {
        return api
          .get(`/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/devices`)
          .then((data) => {
            commit('setDevices', data);
            return data;
          })
          .catch((error) => {
            throw error;
          });
      } else if(getters.currentUserIsConsumer) {
        return dispatch('getConsumerDevices')
      }

    },

    getConsumerDevice({ commit, rootGetters }, deviceId) {
      const connection = rootGetters.connection;
      const baseUrlOrg = rootGetters.baseUrlOrg;

      return api
        .get(`/${baseUrlOrg}/connections/${connection.id}/devices/${deviceId}`)
        .then((data) => {
          commit('setDevice', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getConnectionDevices({ rootGetters }, connectionId) {
      const baseUrlOrg = rootGetters.baseUrlOrg;

      return api
        .get(`/${baseUrlOrg}/connections/${connectionId}/devices`)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    async getConsumerDevices({ commit, dispatch }, connectionId) {
      let devices = await dispatch('getConnectionDevices', connectionId);
      commit('setDevices', devices);
      return devices
    },

    // Device not actually created until provisioned on RMS machine,
    // so don't set device here.

    /* eslint-disable-next-line no-unused-vars */
    createDevice({ commit }, data) {
      const currentUser = this.getters['currentUser'];

      return api
        .post(
          `/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/devices`,
          data
        )
        .then((data) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },


    updateDevice({ commit }, data) {
      const currentUser = this.getters['currentUser'];

      return api
        .put(
          `/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/devices/${data.id}`,
          data
        )
        .then((data) => {
          commit('setDevice', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    deleteDevice({ commit }, id) {
      const currentUser = this.getters['currentUser'];

      return api
        .delete(
          `/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/devices/${id}`
        )
        .then((response) => {
          commit('setDevice', null);
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
