let loggingIn = false;
let loginInterval = null;
const sessionIdKey = "x-ss-sessionId";
import * as Auth from "@/modules/authv2";
import { api } from "@/modules/apiv2";

export default {
  state: {
    currentUser: null,
    loggedInStatus: 'pending',
    passwordChangeState: localStorage.getItem("x-ss-passwordChanged"),
  },

  getters: {
    currentUser(state) {
      return state.currentUser;
    },

    currentUserName(state) {
      return state.currentUser?.idTokenClaims.name;
    },

    currentUserClaims(state) {
      return state.currentUser?.idTokenClaims
    },

    currentUserOrgType(state) {
      let orgType = state.currentUser?.idTokenClaims.extension_OrgType.toLowerCase();
      let orgTypeString;
      if (orgType && orgType.indexOf("owner") !== -1) {
        orgTypeString = "owner";
      }
      else if (orgType && orgType.indexOf("consumer") !== -1) {
        orgTypeString = "consumer";
      }
      else {
        orgTypeString = undefined;
      }
      return orgTypeString;
    },

    currentUserAccountType(state) {
      return state.currentUser?.idTokenClaims.extension_AccountType;
    },

    currentUserOrgId(state) {
      return state.currentUser?.idTokenClaims.extension_OrgId;
    },

    currentUserAccountId(state) {
      return state.currentUser?.idTokenClaims.extension_CosmosDbAccountId;
    },

    currentUserIsOwner(state, getters) {
      return getters.currentUserOrgType === 'owner';
    },

    currentUserIsConsumer(state, getters) {
      return getters.currentUserOrgType === 'consumer';
    },

    currentUserIsDispatcher(state, getters) {
      return getters.currentUserAccountType === 'Dispatcher';
    },

    currentUserIsImageViewer(state, getters) {
      return getters.currentUserAccountType === 'ImageViewer';
    },

    currentUserApiPrefix(state, getters) {
      return getters.currentUserIsDispatcher ? process.env.VUE_APP_DISPATCHER_API_PREFIX :
             getters.currentUserIsImageViewer ? process.env.VUE_APP_IMAGE_VIEWER_API_PREFIX :
             getters.currentUserIsOwner ? process.env.VUE_APP_OWNER_API_PREFIX :
             getters.currentUserIsConsumer ? process.env.VUE_APP_CONSUMER_API_PREFIX : "none";
    },

    baseUrl(state, getters) {
      // if this gets used in the events screens, the api that it points to needs to have the events in the org api
      return `/${getters.currentUserApiPrefix}/${getters.currentUserOrgType}s`
    },

    baseUrlOrg(state, getters) {
      return `${getters.baseUrl}/${getters.currentUserOrgId}`
    },

    loggedInStatus(state) {
      return state.loggedInStatus;
    },

    passwordChangeState(state) {
      return state.passwordChangeState
    },
  },

  mutations: {
    setCurrentUser(state, currentUser) {
      if (!currentUser || !currentUser.idToken) {
        throw new Error("trying to store an invalid currentUser");
      }
      state.currentUser = currentUser;
    },

    setLoggedInStatus(state, loggedInStatus) {
      state.loggedInStatus = loggedInStatus;
    },

    setPasswordChangeState(state, passwordChangeState) {
      state.passwordChangeState = passwordChangeState;
      localStorage.setItem("x-ss-passwordChanged", passwordChangeState);
    },

    deletePasswordChangeState(state) {
      state.passwordChangeState = undefined;
      localStorage.removeItem("x-ss-passwordChanged");
    },
  },

  actions: {
    currentUserIsLoggedIn() {
      return Auth.loggedInTest();
    },

    async updateCurrentUser({ dispatch, commit, getters }, operationType = 'read') {
      let user = getters['currentUser'];

      if (!user || !user.idToken || (user.idTokenClaims.exp * 1000) <= Date.now()) {

        user = await Auth.getTokenResponse(operationType);
        if (user) {
          commit('setCurrentUser', user);
          dispatch('loginUser');
        }
      }

      return user;
    },

    loginUser({ dispatch }) {
      let intervalTime_sec = parseInt(process.env.VUE_APP_LOGIN_TIMER_INTERVAL_SEC);
      dispatch('loginUserInterval');
      // loginUser is sometimes called multiple times, but we only want one
      // timer interval running at a time. This check makes sure we only ever
      // have one timer kicked off.
      if (loginInterval !== null)
      {
        clearInterval(loginInterval);
      }
      loginInterval = setInterval(function() { dispatch('loginUserInterval'); }, intervalTime_sec * 1000);
    },

    loginUserInterval({ commit, getters }) {
      if (loggingIn || getters.passwordChangeState === 'changing') return;
      loggingIn = true;
      let user = getters['currentUser'];
      const baseUrl = getters['baseUrl'];
      const loggedInStatus = getters['loggedInStatus'];
      const sessionId = localStorage.getItem(sessionIdKey);

      if (user && loggedInStatus === 'pending' || loggedInStatus === 'loggedInError' || loggedInStatus === 'loggedIn') {
        let requestUrl = new URL(`${baseUrl}/${user.idTokenClaims.extension_OrgId}/accounts/${user.idTokenClaims.extension_CosmosDbAccountId}/login`, process.env.VUE_APP_BASE_URI);

        if (sessionId) {
          requestUrl.searchParams.set(`sessionId`, sessionId);
        }

        if (getters.passwordChangeState === 'changed') {
          requestUrl.searchParams.set(`passwordChanged`, 'true');
          commit('deletePasswordChangeState');
        }

        api
          .post(requestUrl.toString())
          .then((response) => {
            // check if this is our first login

            if (response.sessionId) {
              localStorage.setItem(sessionIdKey, response.sessionId);
            }
            if (loggedInStatus !== 'loggedIn') {
              commit('setLoggedInStatus', 'loggedIn');
            }
            loggingIn = false;
            if ('passwordChanged' in response) {
              const changeState = getters.passwordChangeState;
              if (!response.passwordChanged && typeof changeState != undefined) {
                // this is our first login
                commit('setPasswordChangeState', 'changing');
                Auth.forcePasswordChange();
              }
            }
            // We are logged in and good to go
          })
          .catch((error) => {
            localStorage.removeItem(sessionIdKey);
            console.error("error caught in loginUser in current user", error);
            if (error.status === 409) {
              commit('setLoggedInStatus', 'loggedInFailed');
            } else {
              commit('setLoggedInStatus', 'loggedInError');
            }
            loggingIn = false;
          });
      } else {
        loggingIn = false;
      }
    },

    logoutUser({ commit, getters }) {
      let user = getters['currentUser'];

      if (user === null) {
        return;
      }

      const baseUrl = getters['baseUrl'];
      const loggedInStatus = getters['loggedInStatus'];
      const sessionId = localStorage.getItem(sessionIdKey);
      let requestUrl = `${baseUrl}/${user.idTokenClaims.extension_OrgId}/accounts/${user.idTokenClaims.extension_CosmosDbAccountId}/logout`;

      if (sessionId) {
        requestUrl += `?sessionId=${sessionId}`;
      }

      if (user && loggedInStatus === 'loggedIn') {
        return api
        .post(requestUrl)
        .then(() => {
          commit('setLoggedInStatus', 'pending');
          clearInterval(loginInterval);
          loginInterval = null;
        })
        .catch((error) => {
          console.error(error);
        });
      }
    },

    logoutCurrentUser({ dispatch }) {
      dispatch('logoutUser')
      .then(() => {
        Auth.logout();
      });
    },

    loginCurrentUser() {
      Auth.login();
    }
  },
};
