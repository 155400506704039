import { api } from "@/modules/apiv2";
import { convertStringtoAPIDate } from '@/mixins.js';

export default {
  state: {
    event: null,
    events: [],
    srcEvents: [],
    nonRmsEvents: [],
    trainingEvent: null,
  },

  getters: {
    event(state) {
      return state.event;
    },

    events(state) {
      return state.events;
    },

    srcEvents(state) {
      return state.srcEvents;
    },

    nonRmsEvents(state) {
      return state.nonRmsEvents;
    },

    trainingEvent(state) {
      return state.trainingEvent;
    },
  },

  mutations: {
    setEvent(state, event) {
      if (event) {
        state.event = event;
      }
    },

    setEvents(state, events) {
      if (events) {
        state.events = events;
      }
    },

    setSrcEvents(state, events) {
      if (events) {
        state.srcEvents = events;
      }
    },

    setNonRmsEvents(state, events) {
      if (events) {
        state.nonRmsEvents = events;
      }
    },

    setTrainingEvent(state, trainingEvent) {
      if (trainingEvent) {
        state.trainingEvent = trainingEvent;
      }
    },
  },

  actions: {
    getEvent({ commit }, eventId) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(`${baseUrl}/events/${eventId}`)
        .then((data) => {
          commit('setEvent', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    /* eslint-disable-next-line no-unused-vars */
    deleteEvent({ commit }, data) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .delete(`${baseUrl}/devices/${data.deviceid}/events/${data.eventid}`)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    /* eslint-disable-next-line no-unused-vars */
    createTrainingEvent({ commit }, data) {
      const baseUrl = this.getters.baseUrlOrg;
      return api
        .post(
          `${baseUrl}/events`,
          data
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },

    getEvents({ commit }, params) {
      const baseUrl = this.getters.baseUrlOrg;
      const baseurl = `${baseUrl}/events?`;
      let setEventType = 'setEvents';
      let queryParams = [];


      if (params.startDate) {
        let startDate = convertStringtoAPIDate(params.startDate);
        queryParams.push(`Start=${startDate}`);
      }

      if (params.endDate) {
        let endDate = convertStringtoAPIDate(params.endDate);
        queryParams.push(`End=${endDate}`);
      }

      let limit = params.limit || 100;
      queryParams.push(`Limit=${limit}`);

      if (params.token) {
        queryParams.push(`ContinuationToken=${params.token}`);
      }

      if (params.OtherType) {
        queryParams.push(`OtherType=${params.OtherType}`);
        setEventType = 'setNonRmsEvents';
      }

      let filters = params.filters
        .filter((filter) => {
          // Always pass isMaintenanceModeEvent regardless of value
          if (Object.keys(filter)[0] === 'isMaintenanceModeEvent') {
            return true;
          }

          // Always pass isNuisanceAlert regardless of value
          if (Object.keys(filter)[0] === 'isNuisanceAlert') {
            return true;
          }

          // Only send other parameters
          // if a value exists
          return Object.values(filter)[0];
        })
        .map((filter) => {
          return `${Object.keys(filter)[0]}=${Object.values(filter)[0]}`;
        })
        .concat(queryParams)
        .join('&');

      return api
        .get(
          `${baseurl}${filters}`
        )
        .then((data) => {
          commit(setEventType, data);

          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    /* eslint-disable-next-line no-unused-vars */
    getSrcEvents({ commit }, params) {
      const baseUrl = this.getters.baseUrlOrg;
      const baseurl = `${baseUrl}/events?Limit=100&OtherType=banner&Src`;

      return api
        .get(baseurl)
        .then((data) => {
          commit('setSrcEvents', data);

          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    /* eslint-disable-next-line no-unused-vars */
    createMessage({ commit }, data) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .post(
            `${baseUrl}/events`,
            data
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
    },
  },
};
