import { api } from "@/modules/apiv2";

export default {
    state: {
      dispatchableEvents: [],
      dispatches: [],
    },

    getters: {
      dispatchableEvents(state) {
        return state.dispatchableEvents;
      },

      dispatches(state) {
        return state.dispatches;
      },
    },

    mutations: {
      setDispatchableEvents(state, dispatchableEvents) {
        state.dispatchableEvents = dispatchableEvents;
      },

      setDispatches(state, dispatches) {
        state.dispatches = dispatches;
      },
    },

    actions: {
      getDispatchableEvents({ commit }, startTime) {
        const baseUrl = this.getters.baseUrlOrg;
        let getUrl = `${baseUrl}/Events/Dispatchable`

        if (startTime) {
          getUrl += `?startTime=${startTime}`
        }

        return api
        .get(getUrl)
        .then((data) => {
          commit('setDispatchableEvents', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
      },

      getDispatches({ commit }, allDispatches=false) {
        const baseUrl = this.getters.baseUrlOrg;
        const fullUrl = `${baseUrl}/dispatches${allDispatches ? "?complete=1" : ""}`;

        return api
        .get(fullUrl)
        .then((data) => {
          commit('setDispatches', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
      },

      /* eslint-disable-next-line no-unused-vars */
      updateDispatch({ commit }, data) {
        const baseUrl = this.getters.baseUrlOrg;

        var input = new Object();
        input.UpdatedSubscribers = data.UpdatedSubscribers;
        input.TimeToLive = data.TimeToLive;

        return api
          .put(
            `${baseUrl}/dispatches/${data.id}`,
            input
          )
          .then((data) => {
            return data;
          })
          .catch((error) => {
            throw error;
          });
      },

      /* eslint-disable-next-line no-unused-vars */
      createDispatch({ commit }, dispatchInput) {
        const baseUrl = this.getters.baseUrlOrg;

        return api
          .post(
            `${baseUrl}/dispatches`,
            dispatchInput
          )
          .then((data) => {
            return data;
          })
          .catch((error) => {
            throw error;
          });
      },
    },
  };
