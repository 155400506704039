import { api } from "@/modules/apiv2";

export default {
  state: {
    downloads: [],
    download: null,
  },

  getters: {
    downloads(state) {
      return state.downloads;
    },

    download(state) {
      return state.download;
    },
  },

  mutations: {
    setDownloads(state, downloads) {
      state.downloads = downloads;
    },

    setDownload(state, download) {
      state.download = download;
    },
  },

  actions: {
    approveDownload({ commit }, exportId) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .put(`${baseUrl}/exports/${exportId}/approve`)
        .then((data) => {
          commit('setDownload', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getDownloads({ commit }) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(`${baseUrl}/exports`)
        .then((data) => {
          commit('setDownloads', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    getDownload({ commit }, exportId) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .get(
          `${baseUrl}/exports/${exportId}`
        )
        .then((data) => {
          commit('setDownload', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    createDownload({ commit }, data) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .post(
          `${baseUrl}/devices/${data.deviceId}/events/${data.eventId}`,
          data
        )
        .then((data) => {
          commit('setDownload', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    deleteDownload({ commit }, exportId) {
      const baseUrl = this.getters.baseUrlOrg;

      return api
        .delete(
          `${baseUrl}/exports/${exportId}`
        )
        .then((response) => {
          commit('setDownload', null);
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
