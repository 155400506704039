<template>
    <floating-chat
    :content="faqBotSource"
    :textopen="componentText"
    :onMouseover="onMouseOver"
    :onMouseleave="onMouseLeave"
    textColor="#01407d"
    />
</template>

<script>

import FloatingChat from '@/components/FloatingChat.vue';

export default {
    name: "SecureChatBot",

    components: {
      FloatingChat
    },

    data() {
      return {
        hover: false
      }
    },

  computed: {
    faqBotSource() {
      return this.$store.getters['webChatURL'];
    },
    componentText() {
      return this.hover ? "Need help? Secure FAQ Bot" : "?";
    },
  },

  methods: {
    onMouseOver() {
      this.hover = true;
    },

    onMouseLeave() {
      this.hover = false;
    }
  },

  async beforeCreate() {
    await this.$store.dispatch('populateWebChatURL');
  }
};
</script>
