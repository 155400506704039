const APP_API_PREFIX = process.env.VUE_APP_OWNER_API_PREFIX;
import { api } from "@/modules/apiv2";

export default {
  state: {
    summary: null,
  },

  getters: {
    summary(state) {
      return state.summary;
    },
  },

  mutations: {
    setSummary(state, summary) {
      state.summary = summary;
    },
  },

  actions: {
    getSummary({ commit }) {
      const currentUser = this.getters['currentUser'];

      return api
        .get(`/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/summary`)
        .then((data) => {
          commit('setSummary', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    updateSummary({ commit }, data) {
      const currentUser = this.getters['currentUser'];

      return api
        .patch(
          `/${APP_API_PREFIX}/owners/${currentUser.idTokenClaims.extension_OrgId}/summary`,
          data
        )
        .then((data) => {
          commit('setSummary', data);
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
